import {createStore, combineReducers, applyMiddleware} from 'redux'
import {reducer as toastr} from 'react-redux-toastr';
import {UserInfoService, UserMenusService} from './services/AuthService';
import {UserTypesService, UserRolesService, AppSettingsService, TrmTypesService, CrmActionsService} from './services/CommonServices';
import {VendorCategoryListService} from './services/VendorsServices';
import {DocumentCategoryListService, DocumentTemplateListService} from './services/CommonServices';
import {FilterMetadataService} from './services/CommonServices';
import {
    GetCompanyListService,
    GetDriverTypeService,
    GetJobSourceService,
    GetLoadTypeService,
    GetTrailerTypeService,
    GetTaxFormTypeService
} from './services/JobService';
import {tableNames} from './utilities/Constants';
import {
    ServiceCategoryListParent,
    AssetTypeListService,
    FuelTypeListService,
    ServicesListService,
    ServiceCategoryListChild
} from './services/Services';
import {
    GetActiveStateService,
    GetActiveCountryService,
    GetActiveCityService,
    GetStateService,
    GetCityService
} from './services/GeoServices';
import {CandidateLicenseType} from './services/CandidateService';

import {CarrierCategory, ShipperCategory} from './services/LoadService'


const AppMiddleware = store => next => action => {
    
    switch (action.type) {
        case 'GET_USER_TYPES' :
            UserTypesService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', userTypes: response.data});
            });
            break;
        case 'GET_USER_ROLES' :
            UserRolesService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', userRoles: response.data});
            });
            break;
            case 'GET_CRM_ACTION' :
                CrmActionsService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', crmAction: response.data});
            });
            break;
        case 'GET_TRM_TYPES' :
            TrmTypesService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', trmTypes: response.data});
            });
            break;
        case 'GET_USER_INFO' :
            UserInfoService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', userInfo: response.data});
            });
            break;
        case 'GET_USER_MENUS' :
            UserMenusService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', userMenus: response.data});
            });
            break;
        case 'GET_APP_SETTINGS' :
            AppSettingsService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', appSettings: response.data});
            });
            break;
        case 'GET_VENDOR_CATEGORIES' :
            VendorCategoryListService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', vendorCategories: response.data});
            });
            break;
        case 'GET_COMPANY_TYPE' :
            GetCompanyListService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', jobCompanies: response.data});
                    
            console.log( response.data)
            });
            break;
        case 'GET_TRAILER_TYPE' :
            GetTrailerTypeService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', jobTrailers: response.data});
            });
            break;
        case 'GET_TAX_FORM_TYPE' :
            GetTaxFormTypeService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', jobTaxForms: response.data});
            });
            break;
        case 'GET_DRIVER_TYPE' :
            GetDriverTypeService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', jobDrivers: response.data});
            });
            break;
        case 'GET_LOAD_TYPE' :
            GetLoadTypeService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', jobLoads: response.data});
            });
            break;
        case 'GET_JOB_SOURCE' :
            GetJobSourceService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', jobSources: response.data});
            });
            break;

        case 'GET_HIRING_AGENTS' :
            UserRolesService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', hiringAgents: response.data});
            });
            break;

        case 'GET_CLIENT_CATEGORIES' :
            DocumentCategoryListService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', clientCategories: response.data});
            });
            break;
        case 'GET_CARRIER_CATEGORIES' :
            CarrierCategory().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', carrierCategories: response.data});
            });
            break;
        case 'GET_SHIPPER_CATEGORIES' :
            ShipperCategory().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', shipperCategories: response.data});
            });
            break;
        case 'GET_ESIGN' :
            store.dispatch({
                type: 'set', isSign: [
                    {"id": 0, "name": "No"},
                    {"id": 1, "name": "Yes"},
                ]
            });
            break;
        case 'GET_JOB_STATUS' :
            store.dispatch({
                type: 'set', jobStatusList: [
                    {"id": 1, "name": "Open"},
                    {"id": 2, "name": "On Hold"},
                    {"id": 3, "name": "Hired"},
                ]
            });
            break;
        case 'GET_JOB_APPLICATION_STATUS' :
            store.dispatch({
                type: 'set', jobApplicationStatusList: [
                    {"id": 1, "name": "Open"},
                    {"id": 2, "name": "Screening"},
                    {"id": 3, "name": "Rejected"},
                    {"id": 4, "name": "Hired"},
                ]
            });
            break;
        case 'GET_DOCUMENT_TEMPLATES' :
            DocumentTemplateListService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', documentTemplates: response.data});
            });
            break;

        case 'GET_PARENT' :
            ServiceCategoryListParent().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', categoryParents: response.data});
            });
            break;

        case 'GET_CHILD' :
            ServiceCategoryListChild().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', categoryChild: response.data});
            });
            break;

        case 'GET_ASSET_TYPES' :
            AssetTypeListService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', assetTypes: response.data});
            });
            break;
        case 'GET_FUEL_TYPES' :
            FuelTypeListService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', fuelTypes: response.data});
            });
            break;

        case 'GET_ACTIVE_COUNTRIES' :
            GetActiveCountryService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', countryMasters: response.data});

            });
            break;
        case 'GET_ACTIVE_STATES' :
            GetActiveStateService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', stateMaster: response.data});
                console.log("storestatemaster", response.data)

            });
            break;
        case 'GET_ACTIVE_CITIES' :
            GetActiveCityService().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', cityMaster: response.data});

            });
            break;
        case 'GET_MERGE_METADATA_VENDOR' :
            FilterMetadataService({tableName: tableNames.vendors, filtered: 1}).then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', mergeMetaDataVendor: response.data});
            });
            break;
        case 'GET_MERGE_METADATA_CARRIER' :
            FilterMetadataService({tableName: tableNames.carriers, filtered: 1}).then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', mergeMetaDataCarrier: response.data});
            });
            break;
        case 'GET_MERGE_METADATA_JOB' :
            FilterMetadataService({tableName: tableNames.jobs, filtered: 1}).then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', mergeMetaDataCJob: response.data});
            });
            break;
        case 'GET_PROFILE_STATUS' :
            store.dispatch({
                type: 'set', profileStatusList: [
                    {"id": 1, "name": "In Progress"},
                    {"id": 2, "name": "Submitted"},

                ]
            });
            break;
        case 'GET_THEME_FONT' :
            store.dispatch({
                type: 'set', themeFontChoose: [
                    {"id": 1, "name": "Arial (sans-serif)"},
                    {"id": 2, "name": "Luminari (fantasy)"},
                    {"id": 3, "name": "Times New Roman (serif)"},
                    {"id": 4, "name": "San (serif)"},
                ]
            });
            break;
        case 'GET_EXTERNAL' :
            store.dispatch({
                type: 'set', externalSelect: [
                    {"id": 0, "name": "No"},
                    {"id": 1, "name": "Yes"},
                ]
            });
            break;

        case 'GET_HEAVYDUTY' :
            store.dispatch({
                type: 'set', heavydutySelect: [
                    {"id": 0, "name": "No"},
                    {"id": 1, "name": "Yes"},
                ]
            });
            break;

        case 'GET_VERIFIED' :
            store.dispatch({
                type: 'set', verifiedSelect: [
                    {"id": 0, "name": "No"},
                    {"id": 1, "name": "Yes"},
                ]
            });
            break;

        case 'GET_AVAILABLE' :
            store.dispatch({
                type: 'set', availableSelect: [
                    {"id": 0, "name": "No"},
                    {"id": 1, "name": "Yes"},
                ]
            });
            break;

        case 'GET_HEALTH_INSURANCE' :
            store.dispatch({
                type: 'set', healthInsuranceSelect: [
                    {"id": 0, "name": "No"},
                    {"id": 1, "name": "Yes"},
                ]
            });
            break;

        case 'GET_IS_PRIMARY' :
            store.dispatch({
                type: 'set', isprimarySelect: [
                    {"id": 0, "name": "No"},
                    {"id": 1, "name": "Yes"},
                ]
            });
            break;

        case 'GET_IS_AUTHREQUIRED' :
            store.dispatch({
                type: 'set', isAuthRequired: [
                    {"id": 0, "name": "No"},
                    {"id": 1, "name": "Yes"},
                ]
            });
            break;

        case 'GET_SHOW_IN_COB' :
            store.dispatch({
                type: 'set', showInCob: [
                    {"id": 0, "name": "No"},
                    {"id": 1, "name": "Yes"},
                ]
            });
            break;

        case 'GET_REGISTRATION_REQUIRED' :
            store.dispatch({
                type: 'set', registrationRequired: [
                    {"id": 0, "name": "No"},
                    {"id": 1, "name": "Yes"},
                ]
            });
            break;

        case 'GET_HOME_FILTER' :
            store.dispatch({
                type: 'set', homeFilterSelect: [
                    {"id": 0, "name": "All"},
                    {"id": 1, "name": "Recently Viewed"},
                    {"id": 2, "name": "Popular Item"},
                    {"id": 3, "name": "New Arrival"},
                    {"id": 4, "name": "Bundled Offer"},
                ]
            });
            break;

            case 'GET_IS_VERIFIED':
                store.dispatch({
                    type: 'set', verified: [
                        { "id": 0, "name": "No" },
                        { "id": 1, "name": "Yes" },
                    ]
                });
                break;

        case 'GET_DRIVER_LICENSE' :
            CandidateLicenseType().then(response => {
                if (response && response.data)
                    store.dispatch({type: 'set', licenseTypes: response.data});

            });
            break;

    }
    return next(action);
}
const initialState = {
    sidebarShow: 'responsive',
    userInfo: null,
    userMenus: null,
    carrierData: null,
    userData: null,
    userTypes: null,
    appSettings: [],
    userRoles: null,
    hiringAgents: null,
    vendorData: null,
    vendorCategories: null,
    countryMasters: null,
    clientCategories: null,
    jobCompanies: null,
    jobTaxForms: null,
    isSign: null,
    externalSelect: null,
    heavydutySelect: null,
    themeFontChoose: null,
    taskTypeData: null,
    formList: false,
    goBackUrl: '',
    mergeMetaDataVendor: [],
    mergeMetaDataCarrier: [],
    healthInsuranceSelect: null
}

const changeState = (state = initialState, {type, ...rest}) => {
    switch (type) {
        case 'set':
            return {...state, ...rest}
        default:
            return state
    }
}

const store = createStore(combineReducers({changeState, toastr}), applyMiddleware(AppMiddleware));
export default store
