import {POST, GET, UPLOAD, DELETE, PUT, MIXED} from './ApiContract';

export const CandidateListService = (payload) => {
    return POST('clients/candidates/list', payload);
}

export const InactiveCandidateListService = (payload) => {
    return POST('clients/candidates/inactivelist', payload);
}


export const CandidateDeleteService = (payload) => {
    return DELETE('clients/candidates/delete', null, payload);
}

export const CandidateSelectedDeleteService = (payload) => {
    return DELETE('clients/candidates/deleteselected', null, payload);
}

export const CandidateDeleteAllService = (payload) => {
    return DELETE('clients/candidates/deleteall', null, payload);
}

export const GetCandidateByIdService = (payload) => {
    return GET('clients/candidatefixer/get', payload);
}

export const CandidateAddService = (payload) => {
    return POST('clients/candidates/add', payload);
}

export const CandidateEditService = (payload) => {
    return POST('clients/candidates/edit', payload);
}

export const AddressAddService = (payload) => {
    return POST('clients/candidates/address/add', payload);
}

export const EmploymentAddService = (payload) => {
    return POST('clients/candidates/employment/history/add', payload);
}
export const EducationAddService = (payload) => {
    return POST('clients/candidates/education/add', payload);
}
export const EmergencyAddService = (payload) => {
    return POST('clients/candidates/emergency/contact/add', payload);
}

/*******CLIENT EMAIL SETTINGS *********/
export const GetEmailSettingListService = (payload) => {
    return POST('clients/emailaccountsettings/list', payload);
}

export const AddEmailSettingService = (payload) => {
    return POST('clients/emailaccountsettings/add', payload);
}

export const DeleteEmailSettingService = (payload) => {
    return DELETE('clients/emailaccountsettings/delete', payload);
}

export const EditEmailSettingService = (payload) => {
    return POST('clients/emailaccountsettings/edit', payload);
}

export const GetEmailSettingByIdService = (payload) => {
    return GET('clients/emailaccountsettings/get', payload);
}

/******* CLIENT PROFILE *********/
export const GetClientProfileListService = (payload) => {
    return POST('clients/profile/list', payload);
}

export const AddClientProfileService = (payload) => {
    return UPLOAD('clients/profile/add', payload);
}

export const DeleteClientProfileService = (payload) => {
    return DELETE('clients/profile/delete', payload);
}

export const EditClientProfileService = (payload) => {
    return UPLOAD('clients/profile/edit', payload);
}

export const GetClientProfileByIdService = (payload) => {
    return GET('clients/profile/get', payload);
}

/********CANDIDATE UPLOAD SERVICE************/
export const CandidateDataFilesService = (payload) => {
    return POST('clients/candidates/files/list', payload);
}

export const CandidateDataFileUploadService = (payload) => {
    return UPLOAD('clients/candidates/files/upload', payload);
}
export const CandidateDownloadService = (payload) => {
    return GET('clients/candidates/files/download', payload);
}

export const CandidateLicenseType = (lid, payload) => {
    return GET('common/candidates/licensetypes/get?countryId=' + lid, payload);
}

export const CandidateTemplate = (payload) => {
    return GET('clients/candidates/template/download', payload);
}
